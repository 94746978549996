import {Injectable} from "@angular/core";
import {SnotifyPosition, SnotifyService, SnotifyToastConfig} from "ng-snotify";
import {BehaviorSubject} from "rxjs";
import {Alert, IAlert} from "../../models/alert.model";

@Injectable({providedIn: 'root'})
export class AlertService {
	private alerts: IAlert[];
	$alerts: BehaviorSubject<IAlert[]> = new BehaviorSubject<IAlert[]>([]);

	alertConfig: SnotifyToastConfig = {
		// timeout: 2000,
		// showProgressBar: false,
		// closeOnClick: true,
		// pauseOnHover: false,
		position: SnotifyPosition.centerTop,

	};

	constructor(private snotify: SnotifyService) {
		this.alerts = [];
		this.set();
	}

	push(title: string, message: string, type: string) {
		const alert = new Alert();
		alert.title = title;
		alert.message = message;
		alert.type = type;
		this.alerts.push(alert);
		this.set();
	}

	public pushSuccess(title: string, message: string) {
		this.snotify.success(message, title, this.alertConfig);
	}


	public pushError(title: string, message: string) {
		this.snotify.error(message, title,
			this.alertConfig);
	}


	all(): IAlert[] {
		this.get();
		return this.alerts;
	}

	clear() {
		this.alerts = [];
		this.set();
	}

	private set(): void {
		try {
			localStorage.setItem("ALERTS", JSON.stringify(this.alerts));
		} catch (e) {
			console.error("Error saving to localStorage", e);
		}
	}

	private get() {
		try {
			this.alerts = JSON.parse(localStorage.getItem("ALERTS"));
		} catch (e) {
			console.error("Error getting data from localStorage", e);
		}
	}
}
