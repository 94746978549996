import { environment } from "../../../environments/environment";
import { DatasourceConfig } from "./datasource.config";
import { DataSource } from "./domain/datasource.model";

// Nombres de datasources para ser referenciados desde cualquier parte de la app

export const DATASOURCE_NAMES = {
	MODEL_AgeRangePayrollModel: 'AgeRangePayrollModel',
	MODEL_WorkerTypePayrollModel: 'WorkerTypePayrollModel',
	MODEL_AgeRangeModel: 'AgeRangeModel',
	MODEL_ParamLevelModel: 'ParamLevelModel',
	MODEL_NumberWorkersModel: 'NumberWorkersModel',
	MODEL_MonthlyRemuneration: 'MonthlyRemuneration',
	MODEL_FrecuencyModel: 'FrecuencyModel',
	MODEL_PaymentFrecuencyModel: 'PaymentFrecuencyModel',
	MODEL_WorkerTypeRateModel: 'WorkerTypeRateModel',
	MODEL_TARIFF: 'TariffModel',

};

// EN LA CONFIGURACION DEBE USARSE COMO LLAVES LOS MISMOS VALORES ESPECIFICADOS EN EL DATASOURCE_NAMES ANTERIOR
export function rootDatasourceConfig() {
	const datasources: { [id: string]: DataSource } = {};

	datasources[DATASOURCE_NAMES.MODEL_AgeRangePayrollModel] = {
		model: DATASOURCE_NAMES.MODEL_AgeRangePayrollModel,
		url: environment.api.quotation,
		api: {
			LIST: {method: 'GET', url: '/age-range-payroll/', name: 'Listar Parámetro'},
			POST: {method: 'POST', url: '/age-range-payroll/', name: 'Adicionar Parámetro'},
			PUT: {method: 'PUT', url: '/age-range-payroll/', name: 'Actualizar Parámetro'},
			DELETE: {method: 'DELETE', url: '/age-range-payroll/', name: 'Eliminar Parámetro'},
			ACTIVAR: {method: 'POST', url: '/age-range-payroll/activar', name: 'Activar Parámetro'},
		},
	};

	datasources[DATASOURCE_NAMES.MODEL_WorkerTypePayrollModel] = {
		model: DATASOURCE_NAMES.MODEL_WorkerTypePayrollModel,
		url: environment.api.quotation,
		api: {
			LIST: {method: 'GET', url: '/worker-type-payroll/', name: 'Listar Parámetro'},
			POST: {method: 'POST', url: '/worker-type-payroll/', name: 'Adicionar Parámetro'},
			PUT: {method: 'PUT', url: '/worker-type-payroll/', name: 'Actualizar Parámetro'},
			DELETE: {method: 'DELETE', url: '/worker-type-payroll/', name: 'Eliminar Parámetro'},
			ACTIVAR: {method: 'POST', url: '/worker-type-payroll/activar', name: 'Activar Parámetro'},
		},
	};

	datasources[DATASOURCE_NAMES.MODEL_AgeRangeModel] = {
		model: DATASOURCE_NAMES.MODEL_AgeRangeModel,
		url: environment.api.quotation,
		api: {
			LIST: {method: 'GET', url: '/age-range/', name: 'Listar Parámetro'},
			POST: {method: 'POST', url: '/age-range/', name: 'Adicionar Parámetro'},
			PUT: {method: 'PUT', url: '/age-range/', name: 'Actualizar Parámetro'},
			DELETE: {method: 'DELETE', url: '/age-range/', name: 'Eliminar Parámetro'},
			ACTIVAR: {method: 'POST', url: '/age-range/activar', name: 'Activar Parámetro'},
		},
	};
	datasources[DATASOURCE_NAMES.MODEL_ParamLevelModel] = {
		model: DATASOURCE_NAMES.MODEL_ParamLevelModel,
		url: environment.api.quotation,
		api: {
			LIST: {method: 'GET', url: '/param-level/', name: 'Listar Parámetro'},
			POST: {method: 'POST', url: '/param-level/', name: 'Adicionar Parámetro'},
			PUT: {method: 'PUT', url: '/param-level/', name: 'Actualizar Parámetro'},
			DELETE: {method: 'DELETE', url: '/param-level/', name: 'Eliminar Parámetro'},
			ACTIVAR: {method: 'POST', url: '/param-level/activar', name: 'Activar Parámetro'},
		},
	};
	datasources[DATASOURCE_NAMES.MODEL_NumberWorkersModel] = {
		model: DATASOURCE_NAMES.MODEL_NumberWorkersModel,
		url: environment.api.quotation,
		api: {
			LIST: {method: 'GET', url: '/number-workers/', name: 'Listar Parámetro'},
			POST: {method: 'POST', url: '/number-workers/', name: 'Adicionar Parámetro'},
			PUT: {method: 'PUT', url: '/number-workers/', name: 'Actualizar Parámetro'},
			DELETE: {method: 'DELETE', url: '/number-workers/', name: 'Eliminar Parámetro'},
			ACTIVAR: {method: 'POST', url: '/number-workers/activar', name: 'Activar Parámetro'},
		},
	};
	datasources[DATASOURCE_NAMES.MODEL_MonthlyRemuneration] = {
		model: DATASOURCE_NAMES.MODEL_MonthlyRemuneration,
		url: environment.api.quotation,
		api: {
			LIST: {method: 'GET', url: '/monthly-remuneration/', name: 'Listar Parámetro'},
			POST: {method: 'POST', url: '/monthly-remuneration/', name: 'Adicionar Parámetro'},
			PUT: {method: 'PUT', url: '/monthly-remuneration/', name: 'Actualizar Parámetro'},
			DELETE: {method: 'DELETE', url: '/monthly-remuneration/', name: 'Eliminar Parámetro'},
			ACTIVAR: {method: 'POST', url: '/monthly-remuneration/activar', name: 'Activar Parámetro'},
		},
	};
	datasources[DATASOURCE_NAMES.MODEL_FrecuencyModel] = {
		model: DATASOURCE_NAMES.MODEL_FrecuencyModel,
		url: environment.api.quotation,
		api: {
			LIST: {method: 'GET', url: '/frequency/', name: 'Listar Parámetro'},
			POST: {method: 'POST', url: '/frequency/', name: 'Adicionar Parámetro'},
			PUT: {method: 'PUT', url: '/frequency/', name: 'Actualizar Parámetro'},
			DELETE: {method: 'DELETE', url: '/frequency/', name: 'Eliminar Parámetro'},
			ACTIVAR: {method: 'POST', url: '/frequency/activar', name: 'Activar Parámetro'},
		},
	};
	datasources[DATASOURCE_NAMES.MODEL_PaymentFrecuencyModel] = {
		model: DATASOURCE_NAMES.MODEL_PaymentFrecuencyModel,
		url: environment.api.quotation,
		api: {
			LIST: {method: 'GET', url: '/payment-frequency/', name: 'Listar Parámetro'},
			POST: {method: 'POST', url: '/payment-frequency/', name: 'Adicionar Parámetro'},
			PUT: {method: 'PUT', url: '/payment-frequency/', name: 'Actualizar Parámetro'},
			DELETE: {method: 'DELETE', url: '/payment-frequency/', name: 'Eliminar Parámetro'},
			ACTIVAR: {method: 'POST', url: '/payment-frequency/activar', name: 'Activar Parámetro'},
		},
	};
	datasources[DATASOURCE_NAMES.MODEL_WorkerTypeRateModel] = {
		model: DATASOURCE_NAMES.MODEL_WorkerTypeRateModel,
		url: environment.api.quotation,
		api: {
			LIST: {method: 'GET', url: '/worker-type-rate/', name: 'Listar Parámetro'},
			POST: {method: 'POST', url: '/worker-type-rate/', name: 'Adicionar Parámetro'},
			PUT: {method: 'PUT', url: '/worker-type-rate/', name: 'Actualizar Parámetro'},
			DELETE: {method: 'DELETE', url: '/worker-type-rate/', name: 'Eliminar Parámetro'},
			ACTIVAR: {method: 'POST', url: '/worker-type-rate/activar', name: 'Activar Parámetro'},
		},
	};

	datasources[DATASOURCE_NAMES.MODEL_TARIFF] = {
		model: DATASOURCE_NAMES.MODEL_TARIFF,
		url: environment.api.quotation,
		api: {
			LIST: {method: 'GET', url: '/tariff/', name: 'Listar tarifario Cotizacion'},
			CAMBIAR_ESTADO: {method: 'PUT', url: '/tariff/{id}/{accion}', name: 'Cambiar Estado de tarifario'},
			DELETE: {method: 'DELETE', url: '/tariff/{id}', name: 'Eliminar tarifario'},
			GET_QUOTE: {method: 'GET', url: '/tariff/cotizacion/{id}/{version}', name: 'Obtener tarifario Cotización'},
			GET_QUOTE_VERSIONES: {method: 'GET', url: '/tariff/{id}', name: 'Obtener versiones tarifario Cotización'},
			POST_QUOTE: {method: 'POST', url: '/tariff/cotizacion/', name: 'Adicionar tarifario Cotización'},
			PUT_QUOTE: {method: 'PUT', url: '/tariff/cotizacion/', name: 'Actualizar tarifario Cotización'},
			DELETE_QUOTE: {method: 'DELETE', url: '/tariff/cotizacion/', name: 'Eliminar tarifario Cotización'},
			ACTIVAR_QUOTE: {method: 'POST', url: '/tariff/cotizacion/activar', name: 'Activar tarifario Cotización'},

			POST_PRE_QUOTE: {method: 'POST', url: '/tariff/precotizacion/', name: 'Adicionar tarifario PreCotización'},
			GET_PRE_QUOTE: {method: 'GET', url: '/tariff/precotizacion/{id}/{version}', name: 'Obtener tarifario PreCotización'},
			GET_PRE_QUOTE_VERSIONES: {method: 'GET', url: '/tariff/{id}', name: 'Obtener versiones tarifario PreCotización'},
			PUT_PRE_QUOTE: {method: 'PUT', url: '/tariff/precotizacion/', name: 'Actualizar tarifario PreCotización'},
			DELETE_PRE_QUOTE: {method: 'DELETE', url: '/tariff/precotizacion/', name: 'Eliminar tarifario PreCotización'},
			ACTIVAR_PRE_QUOTE: {method: 'POST', url: '/tariff/precotizacion/activar', name: 'Activar tarifario PreCotización'}
		},
	};

	return {datasources: datasources} as DatasourceConfig;
}

