<div id="common-layout-container" fxLayout [style.justify-content]="align">
	<mat-card class="p-0" [style.width]="width">
		<mat-card-title>
			<div id="common-layout-title-text">
				<ng-content select="[common-layout-title]">
				</ng-content>
			</div>

			<mat-divider></mat-divider>
		</mat-card-title>

		<mat-card-content id="common-layout-content">
			<ng-content select="[common-layout-content]">
			</ng-content>
		</mat-card-content>
	</mat-card>
</div>
