<!--<div id="common-layout-dialog">-->
<!--    <div id="common-layout-dialog-title" [class.sm]="customClass==='sm'">-->
<!--        <ng-content select="[title]">-->
<!--        </ng-content>-->
<!--        <ng-content select="[title-options]">-->
<!--        </ng-content>-->
<!--    </div>-->
<!--    <div id="common-layout-dialog-content">-->
<!--        <ng-content select="[content]">-->
<!--        </ng-content>-->
<!--    </div>-->
<!--    <div id="common-layout-dialog-actions" *ngIf="showActions === true">-->
<!--        <ng-content select="[actions]">-->
<!--        </ng-content>-->
<!--    </div>-->
<!--</div>-->
<mat-card id="common-layout-dialog">
    <mat-card-header [class.sm]="customClass==='sm'">
        <mat-card-title>
            <ng-container *ngTemplateOutlet="title">
            </ng-container>
            <ng-container *ngTemplateOutlet="titleOptions">
            </ng-container>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content [style.maxHeight]="maxHeight">
        <ng-container *ngTemplateOutlet="content">
        </ng-container>
    </mat-card-content>

    <mat-card-actions *ngIf="showActions === true" [class]="actionsClass">
        <ng-container *ngTemplateOutlet="actions">
        </ng-container>
    </mat-card-actions>
</mat-card>
