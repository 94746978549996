import { Routes } from "@angular/router";
import { AuthGuard } from "./core/auth/auth.guard";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";

export const rootRouterConfig: Routes = [
	{
		path: "",
		redirectTo: "tariff",
		pathMatch: "full"
	},
	{
		path: "tariff",
		component: AdminLayoutComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		loadChildren: () => {return import('./views/tariff/tariff.module').then((m) => m.TariffModule);},
		data: {title: "Tarifario", breadcrumb: "Tarifario"}
	},
	{
		path: "params",
		component: AdminLayoutComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		loadChildren: () => import('./views/tariff/parametros/parametros.module').then((m) => m.ParametrosModule)
		,
		data: {title: "Parámetros", breadcrumb: "Parámetros"}
	},
	{
		path: "view",
		component: AdminLayoutComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		loadChildren: () => import('./views/tariff/tariff/tariff.module').then((m) => m.TariffModule)
		,
		data: {title: "Tarifario", breadcrumb: "Tarifario"}
	},
	{
		path: "**",
		redirectTo: "quotation"
	}
];
