// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	keyCloakUrl: "https://protecta.devmente.io:8443/auth",
	soatBaseApiUrl: "protecta.devmente.io:8443/soat",
	sctrBaseApiUrl: "protecta.devmente.io:8443/kuntur",
	integrationApiUrl: "protecta.devmente.io:8443",
	api: {
		quotation: 'https://api.lwlt.dev.protectasecuritycloud.pe',
		stagingProxy: 'https://api.lwlt.dev.protectasecuritycloud.pe/integration'
	},
	paramsConfig: {
		branch: {
			code: 1,
			description: 'Vida'
		},
		product: {
			code: 117,
			description: 'Vida Ley'
		},
		module: {
			/**
			 * Info: Agregar por cada componente el modulo al que pertenece
			 */
			riskCoverageRate: {
				code: 63,
				description: 'Plan Maestro'
			},
			hannoverTable: {
				code: 666,
				description: 'Tabla de Mortalidad'
			},
			complementaryCoverages: {
				basic: 63,
				full: 58,
				special: 59,
				master: 70
			}
		}
	},
	moduleConfig: {
		params: 'Cotizador'
	},
	aws: {
		aws_project_region: 'us-east-1',
        aws_cognito_identity_pool_id: 'us-east-1:594902652773',
        aws_cognito_region: 'us-east-1',
        aws_user_pools_id: 'us-east-1_UU9Z6a0n3',
        aws_user_pools_web_client_id: 'tcq53h177im86qdnodmalvfcl',
		cookieStorage: {
			domain: '.dev.protectasecuritycloud.pe',
			path: '/',
			secure: false
		},
		oauth: {}
	},
	login: 'https://login.core.dev.protectasecuritycloud.pe/#/auth/sign-in'
};

/* export const environment = {
	production: false,
	keyCloakUrl: 'https://tarifario.protectasecurity.pe/auth/',
	soatBaseApiUrl: 'tarifario.protectasecurity.pe/soat',
	sctrBaseApiUrl: 'tarifario.protectasecurity.pe/kuntur',
	integrationApiUrl: 'tarifario.protectasecurity.pe'
}; */
