<div class="app-admin-wrap"  perfectScrollbar  cdkScrollable [dir]='layoutConf.dir'>
	<!-- Header for top navigation layout -->
	<!-- ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT -->
	<!-- Main Container -->
	<mat-sidenav-container
		[dir]='layoutConf.dir'
		class="app-admin-container app-side-nav-container mat-drawer-transition"
		[ngClass]="{
			'navigation-top': layoutConf.navigationPos === 'top',
			'sidebar-full': layoutConf.sidebarStyle === 'full',
			'sidebar-compact': layoutConf.sidebarStyle === 'compact' && layoutConf.navigationPos === 'side',
			'sidebar-compact-big': layoutConf.sidebarStyle === 'compact-big' && layoutConf.navigationPos === 'side',
			'layout-intransition': layoutConf.layoutInTransition,
			'sidebar-opened': layoutConf.sidebarStyle !== 'closed' && layoutConf.navigationPos === 'side',
			'sidebar-closed': layoutConf.sidebarStyle === 'closed',
			'fixed-topbar': layoutConf.topbarFixed && layoutConf.navigationPos === 'side'
		}">
		<!-- SIDEBAR -->
		<!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->


		<!-- Top navigation layout (navigation for mobile screen) -->
		<!-- ONLY REQUIRED FOR **TOP** NAVIGATION MOBILE LAYOUT -->


		<!-- App content -->
		<div class="main-content-wrap" [perfectScrollbar]="scrollConfig"><!--[perfectScrollbar]="scrollConfig" [disabled]="layoutConf.topbarFixed"-->
			<!-- Header for side navigation layout -->
			<!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->


			<div class="rightside-content-hold" [perfectScrollbar]="scrollConfig" [disabled]="!layoutConf.topbarFixed">
				<!-- View Loader -->
				<div class="view-loader" *ngIf="isModuleLoading">
					<div class="spinner">
						<div class="double-bounce1 mat-bg-accent"></div>
						<div class="double-bounce2 mat-bg-primary"></div>
					</div>
				</div>
				<!-- Breadcrumb -->
				<app-breadcrumb></app-breadcrumb>
				<!-- View outlet -->
				<router-outlet></router-outlet>
			</div>
		</div>

	</mat-sidenav-container>

</div>


